
//* main colors *//

$main-color: #00a99d;
$second-color: #6a6865;
$color-link: $main-color;
$color-line: #f1f1f1;
$base-black: #4c4c4c;
$text-color: #4d4d4d;
$base-white: #fff;
$base-grey: #ebebeb;
$submenu-hover: #2d9056;
$dark-grey: #939393;
$background-grey: #f2f3f3;
$background-grey-darker: #999999;
$background-dark: #e7e7e7;

$hamburger-color: $main-color;

//* contrast *//

$main-contrast: #4d4d4d;
$main-contrast-gradient: #000;

//* gradient *//

$main-gradient-after: $main-color;
$main-gradient-before: #21211f;

//* empty image *//

$empty-image-background: $main-color;

//* title *//

$title-color: $main-color;
$title-main-color: $base-white;

//* ul, ol *//

$ul-bullet: $main-color;
$ol-number: $main-color;
$ul-main-bullet: $base-white;
$ol-main-bullet: $base-white;

//* table *//

$table-even-background: $background-grey;
$table-tr-border: $color-line;

//* buttons *//

$btn-primary-background: #757575;
$btn-primary-color: $base-white;
$btn-default-background: $main-color;
$btn-default-color: $base-white;

//* size button *//

$size-button-color: $base-white;
$size-button-backgound: $main-color;

//* contrast button *//

$contrast-button-color: $base-white;
$contrast-button-background: $main-contrast;
$contrast-button-active-background: $main-color;

//* scrollbar *//
$scrollbar-background: $base-grey;
$scrollbar-progress: $main-color;

//* header *//

$header-background: $base-white;
$header-menu-respo-background: $base-white;
$header-link-color: $text-color;
$header-link-hover-color: $main-color;
$header-submenu-background: $base-white;
$header-submenu-link-hover-background: $main-color;
$header-submenu-link-hover-color: $base-white;
$header-contact-background: $main-color;
$header-submenu-li-border-color: $color-line;

$menu-360-color: $main-color;
$menu-icon-color: #c6c6c6;
$header-contact-color: #757575;
$header-contact-respo-color: $base-white;
$header-menu-openner: $main-color;
$header-menu-openner-color: $base-white;
$hamburger-search-color: #c0c0c0;

//* search *//

$search-overlay-background: $main-color;
$search-overlay-color: $base-white;
$search-overlay-link-color: $base-white;
$search-button-color: $main-color;
$search-button-background: $base-white;
$search-color-placeholder: #797979;
$search-item-name-color: $main-color;
$search-item-color: $text-color;
$search-item-breadcrumbs-respo-color: $dark-grey;
$search-close-hover-background: darken($main-color, 10);

//* footer *//

$footer-background: #e6e6e6;
$footer-icon-color: $main-color;
$footer-link-color: $main-color;
$footer-link-hover-color: darken($main-color, 10);
$footer-contact-link-color: $text-color;
$footer-contact-link-hover-color: $main-color;
$footer-links-color: $text-color;
$social-color: $base-white;
$social-background:$main-color;
$copyright-background: #f2f2f2;
$copyright-color: #999999;


$footer-btn: $footer-link-color;
$footer-btn-hover: $base-white;

//* page header *//

$page-header-breadcrumbs-color: $base-white;
$page-header-color: $base-white;

//* person *//

$person-image-background: $base-grey;
$person-text-color: $text-color;
$person-contact-color: $main-color;
$person-small-top-background: $main-color;
$person-small-top-color: $base-white;
$person-small-bottom-background: $base-grey;
$person-small-border-color: $base-white;
$person-small-link-color: $text-color;
$person-small-respo-header-background: $main-color;
$person-small-respo-header-color: $base-white;


$person-grey-bcg-image-background: $base-grey;
$person-grey-bcg-text-color: $text-color;
$person-grey-bcg-contact-color: $main-color;
$person-grey-bcg-small-top-background: $main-color;
$person-grey-bcg-small-top-color: $base-white;
$person-grey-bcg-small-bottom-background: $base-white;
$person-grey-bcg-small-border-color: $base-white;
$person-grey-bcg-small-link-color: $text-color;

$person-main-bcg-image-background: $base-grey;
$person-main-bcg-text-color: #fafafa;
$person-main-bcg-contact-color: #fafafa;
$person-main-bcg-small-top-background: $main-color;
$person-main-bcg-small-odd-background: #d9d9d9;
$person-main-bcg-small-even-background: $base-white;
$person-main-bcg-small-even-background-white-container: #f2f2f2;
$person-main-bcg-small-link-hover-color: $main-color;
$person-main-bcg-small-link-icon-color: $main-color;


//* rolldown *//

$rolldown-head-background: $base-white;
$rolldown-index-background: $main-color;
$rolldown-index-color: $base-white;
$rolldown-ico-color: $base-white;
$rolldown-border-color: #e0e0e0;
$rolldow-hover-background: $main-color;
$rolldow-hover-color: $base-white;
$rolldown-arrow-color: #444444;
$rolldown-arrow-hover-color: $rolldow-hover-color;

//* banner *//

$banner-title-background: $main-color;
$banner-title-color: $base-white;
$banner-text-color: $base-white;
$banner-reverse-background: $banner-title-color;
$banner-reverse-color: $banner-title-background;
$banner-btn-background: $main-color;
$banner-btn-color: $base-white;
$banner-gradient-start: $main-color;

//* timeline *//

$timeline-line-backround: $main-color;
$timeline-line-backround-end: $base-grey;
$timeline-start-ico-background: $main-color;
$timeline-start-ico-color: $base-white;
$timeline-end-ico-background: $base-grey;
$timeline-end-ico-color: $main-color;
$timeline-bullet-background: $main-color;
$timeline-bullet-border: $base-white;
$timeline-link-color: $main-color;
$timeline-item-title-color: $text-color;
$timeline-item-perex-color: $text-color;
$timeline-item-link-color: $main-color;

$timeline-grey-bcg-line-backround: $main-color;
$timeline-grey-bcg-line-backround-end: $base-white;
$timeline-start-grey-bcg-ico-background: $main-color;
$timeline-start-grey-bcg-ico-color: $base-white;
$timeline-end-grey-bcg-ico-background: $base-white;
$timeline-end-grey-bcg-ico-color: $main-color;
$timeline-grey-bcg-bullet-background: $base-white;
$timeline-grey-bcg-bullet-border: $background-grey;
$timeline-grey-bcg-item-title-color: $text-color;
$timeline-grey-bcg-item-perex-color: $text-color;
$timeline-grey-bcg-item-link-color: $main-color;

$timeline-main-bcg-line-backround: $base-white;
$timeline-main-bcg-line-backround-end: lighten($main-color, 5);
$timeline-start-main-bcg-ico-background: $base-white;
$timeline-start-main-bcg-ico-color: $main-color;
$timeline-end-main-bcg-ico-background: lighten($main-color, 5);
$timeline-end-main-bcg-ico-color: $base-white;
$timeline-main-bcg-bullet-background: $base-white;
$timeline-main-bcg-bullet-border: $main-color;
$timeline-main-bcg-item-title-color: #fafafa;
$timeline-main-bcg-item-perex-color: #fafafa;
$timeline-main-bcg-item-link-color: $base-white;


//* download table *//
$download-table-header-color: #6a6865;
$download-table-body-background: $background-grey;
$download-table-tr-hover: #ededed;
$download-table-border-color: #e3e3e3;
$download-table-download-color: $main-color;

$download-table-grey-header-color: $text-color;
$download-table-grey-body-background: #fafafa;
$download-table-grey-tr-hover: #ededed;
$download-table-grey-border-color: $download-table-grey-tr-hover;
$download-table-grey-download-color: $main-color;

$download-table-main-header-color: #fafafa;
$download-table-main-body-background: #fafafa;
$download-table-main-tr-hover: #ededed;
$download-table-main-border-color: $download-table-grey-tr-hover;
$download-table-main-download-color: $main-color;

//* download tiles *//

$download-tile-date-color: #999999;
$download-tile-name-color: #999999;
$donwload-tile-link-color: $main-color;

$download-tile-grey-date-color: $text-color;
$download-tile-grey-name-color: $text-color;
$donwload-tile-grey-link-color: $main-color;
$donwload-tile-grey-ico-text-color: #fff;

$download-tile-main-date-color: #fafafa;
$download-tile-main-name-color: #fafafa;
$donwload-tile-main-link-color: #fafafa;
$donwload-tile-main-ico-text-color: $main-color;

//* important *//
$important-background: $main-color;
$important-color: $base-white;
$important-label-background: $base-white;
$important-label-color: $text-color;
$important-hover-shadow: $main-color;

$important-main-background: $base-white;
$important-main-color: $main-color;
$important-main-label-background: $main-color;
$important-main-label-color: $base-white;
$important-main-hover-shadow: darken($main-color,15);

//* testimonial *//
$quotation-text-color: $text-color;
$quotation-author-color: $text-color;

$quotation-grey-text-color: $text-color;
$quotation-grey-author-color: $text-color;

$quotation-main-text-color: #fafafa;
$quotation-main-author-color: #fafafa;

//* product *//
$product-title-background: $main-color;
$product-title-color: $base-white;
$product-reverse-background: $product-title-color;
$product-reverse-color: $product-title-background;
$product-label-background: $main-color;
$product-label-color: $base-white;
$product-gradient-start: $main-color;

//* event *//

$event-box-title-upcoming: $main-color;
$event-box-title-past: #4f4f4f;
$event-color: #4f4f4f;
$event-title-color: $main-color;
$event-label-background: $main-color;
$event-label-color: $base-white;
$event-date-background: $main-color;
$event-date-color: $base-white;
$event-date-second-color: #999999;

//* card box *//

$cart-box-color: $base-white;

//* card box mini *//

$card-mini-title-color: $text-color;
$card-mini-link-color: $main-color;
$card-mini-ico-color: $main-color;
$card-mini-border-color: $base-grey;
$card-mini-main-color: $base-white;

//* card box small *//

$card-small-ico-color: $main-color;
$card-small-color: $text-color;
$card-small-title-color:$main-color;
$card-small-link-color:$main-color;
$card-small-main-color: $base-white;

//* card box big *//

$card-big-background-color: #f7f7f7;;
$card-big-background-color-gray-box: $base-white;
$card-big-top-color: #4f4f4f;
$card-big-title-color: $main-color;
$card-big-color: $text-color;
$card-big-link-color: $main-color;
$card-big-type-background: #d7f1f0;
$card-big-main-title-color: $base-white;
$card-big-main-link-color: $base-white;
$card-big-main-color: $base-white;

//* video *//

$video-play-background: $base-white;
$video-play-color: $main-color;

//* containers *//

$container-main-background: $main-color;
$container-grey-background: $background-grey;
$container-white-background: $base-white;
$container-dark-background: $background-dark;
$container-dark-with-ico-background: #dcdcdc;

$container-main-color: $base-white;

$separator-main-color: $base-white;
$separator-dark-color: #999;
$separator-white-color: #999;
$separator-grey-color: #999;

//* number listing *//

$number-background: $main-color;
$number-item-background: $background-grey;
$number-background-icon: $background-grey-darker;

$number-grey-background: $main-color;
$number-item-grey-background: $base-white;
$number-grey-background-icon: $background-grey-darker;

$number-main-background: $base-white;
$number-main-background: $main-color;
$number-item-main-background: $base-white;
$number-main-background-icon: $base-white;
$number-main-title-color: $main-color;

//* logo *//

$logo-arrow-color: $main-color;
$logo-arrow-disable-color: #999999;

//* CTA *//

$big-cta-title-color: $main-color;
$big-cta-border-color: #c7c7c7;
$big-cta-patter-title-color: $base-white;
$big-cta-patter-text-color: $base-white;
$big-cta-patter-border-color: $base-white;

//* filter *//

$filter-background: $base-grey;
$filter-color: $text-color;
$filter-link-color: $main-color;
$filter-grey-background: $base-white;
$filter-main-background: $main-color;
$filter-main-color: $base-white;
$filter-main-link-color: $base-white;

//* slider *//

$slider-title-color: $base-white;
$slider-arrow-color: $base-white;
$slider-arrow-hover-color: $main-color;
$slider-service-background:$main-color;
$slider-service-color: $base-white;
$slider-service-line-helper-border-color: $base-white;

$slider-grey-background: $background-grey;
$slider-grey-item-date-color: #666666;;
$slider-grey-item-link-color: $main-color;

//* icons *//

$icons-icon-color: $main-color;
$icons-icon-background: $background-grey;
$icons-icon-title-color: $text-color;
$icons-icon-perex-color: $text-color;

$icons-grey-background-icon-color: $main-color;
$icons-grey-background-icon-background: #fafafa;
$icons-grey-background-title-color: $text-color;
$icons-grey-background-perex-color: $text-color;

$icons-main-background-icon-color: #fafafa;
$icons-main-background-icon-background: $main-color;
$icons-main-background-title-color: #fafafa;
$icons-main-background-perex-color: #fafafa;

//* form *//

$color-placeholder: $text-color;

$border-color: $color-line;
$input-color: #4f4f4f;
$input-background: #f2f2f2;;
$focus-border-color: $main-color;

$select-background: $input-background;
$select-color: $input-color;
$select-li-background: #f2f2f2;
$select-li-hover-background: #ebebeb;
$select-li-selected-background: $main-color;
$select-li-selected-color: $base-white;
$select-arrow-color: $main-color;
$select-li-first-background: #f7f7f7;
$select-li-first-color: #999999;
$select-li-odd-background: $base-white;

$selectric-grey-background: $base-white;

$radio-background: #f7f7f7;
$radio-border: #f7f7f7;
$radio-active-background: $main-color;

$checkbox-background: #f7f7f7;
$checkbox-color: #f7f7f7;
$checkbox-active-color: $main-color;
$checkbox-main-color: $base-white;
$checkbox-main-background: $base-white;

$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

$input-grey-background: $base-white;

//* contact *//

$contact-backgrond: #fafafa;
$contact-icon-color: $main-color;
$contact-link-color: $text-color;

//* page map*//
$page-map-first-level-color: $main-color;
$page-map-link-color: $text-color;

//* line helper *//

$line-helper-border-color: #c7c7c7;
$line-helper-main-border-color: $base-white;

//* mapa *//

$map-marker-color: $base-black;

//* gallery *//

$gallery-title-line-background: $main-color;

//* small crosslink *//

$background-one: #006633;
$color-one: #fff;
$link-color-one: #e7e982;
$background-two: #318b2c;
$color-two: #fff;
$link-color-two: #e7e982;
$background-three: #34a21a;
$color-three: #fff;
$link-color-three: #e7e982;
$background-four: #a4cf77;
$color-four: #026937;
$link-color-four: #026937;
$background-five: #beda9a;
$color-five: #026937;
$link-color-five: #026937;
$background-six: #d6da2c;
$color-six: #026937;
$link-color-six: #026937;

$wizard-grey: #C6C6C6;
$wizard-line: #F1F1F1;
$wizard-active: $main-color;
$wizard-input-border: #757575;
$wizard-placeholder: #C6C6C6;

$datepicker-header-background: darken($main-color, 5);
$datepicker-header-color: $base-white;
$datepicker-header-arrows-color: $base-white;
$datepicker-option-backgorund:$main-color;
$datepicker-option-color: $base-white;
$datepicker-selected-background: $main-color;
$datepicker-selected-color: $base-white;
$datepicker-full-background: $base-white;
$datepicker-th-color: $input-color ;
$datepicker-th-border: $border-color;
$datepicker-year-picker-color: $main-color;
