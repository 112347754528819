
//* banner *//
.banner-item{
  color: $banner-title-color;
  &-inner{
    &:after{
      background: linear-gradient(0deg,
      rgba($main-gradient-after,1) 0%,
      rgba($main-gradient-after,0.44) 25%,
      rgba($main-gradient-after,0.33) 50%,
      rgba($main-gradient-after,0.22) 75%,
      rgba(255,255,255,0.11) 100%);
    }
    &:before{
      background: linear-gradient(0deg,
      rgba($main-gradient-before,1) 0%,
      rgba($main-gradient-before,0.52) 25%,
      rgba($main-gradient-before,0.2) 50%,
      rgba($main-gradient-before,0.02) 75%,
      rgba($main-gradient-before,0) 100%);
    }
  }
  .banner-item-inner{
    &-content{
      &-title{
        color: $banner-title-color;
        @include background-opacity($banner-title-background, 0.75);
      }
      &-btn{
        @include background-opacity($banner-reverse-background, 0.9);
        color: $banner-reverse-color;
        &.btn-banner{
          background: $banner-btn-background;
          color:$banner-btn-color;
        }
      }
    }
  }
  &:focus,
  &:hover{
    color: $banner-title-color;
  }
}

body{
  &.contrast{
    .banner-item{
      &-inner{
        &:before{
          background: linear-gradient(to top,$main-contrast-gradient 0%, transparent 100%);
        }
        &:after{
          display: none;
        }
      }
    }
  }
}


//* banner end *//

//* timeline *//

.timeline-box{
  &:before{
    background: $timeline-line-backround;
  }
  &:after{
    background: linear-gradient(to top,$timeline-line-backround-end 0%, $timeline-line-backround 100%);
  }
  &-start{
    background: $timeline-start-ico-background;
    &:after{
      color: $timeline-start-ico-color;
    }
  }
  &-end{
    background: $timeline-end-ico-background;
    &:after{
      color: $timeline-end-ico-color;
    }
  }
  .timeline-item{
    &-content{
      &-title{
        color: $timeline-item-title-color;
      }
      &-perex{
        color: $timeline-item-perex-color;
      }
      &-btn{
        color: $timeline-item-link-color;
      }
      &:after{
        background: $timeline-bullet-background;
        border-color: $timeline-bullet-border;
      }
    }
  }
}

.bgc-gray{
  .timeline-box{
    &:before{
      background: $timeline-grey-bcg-line-backround;
    }
    &:after{
      background: linear-gradient(to top,$timeline-grey-bcg-line-backround-end 0%, $timeline-grey-bcg-line-backround 100%);
    }
    &-start{
      background: $timeline-start-grey-bcg-ico-background;
      &:after{
        color: $timeline-start-grey-bcg-ico-color;
      }
    }
    &-end{
      background: $timeline-end-grey-bcg-ico-background;
      &:after{
        color: $timeline-end-grey-bcg-ico-color;
      }
    }
    .timeline-item{
      &-content{
        &-title{
          color: $timeline-grey-bcg-item-title-color;
        }
        &-perex{
          color: $timeline-grey-bcg-item-perex-color;
        }
        &-btn{
          color: $timeline-grey-bcg-item-link-color;
        }
        &:after{
          background: $timeline-grey-bcg-bullet-background;
          border-color: $timeline-grey-bcg-bullet-border;
        }
      }
    }
  }
}

.bgc-main{
  .timeline-box{
    &:before{
      background: $timeline-main-bcg-line-backround;
    }
    &:after{
      background: linear-gradient(to top,$timeline-main-bcg-line-backround-end 0%, $timeline-main-bcg-line-backround 100%);
    }
    &-start{
      background: $timeline-start-main-bcg-ico-background;
      &:after{
        color: $timeline-start-main-bcg-ico-color;
      }
    }
    &-end{
      background: $timeline-end-main-bcg-ico-background;
      &:after{
        color: $timeline-end-main-bcg-ico-color;
      }
    }
    .timeline-item{
      &-content{
        &-title{
          color: $timeline-main-bcg-item-title-color;
        }
        &-perex{
          color: $timeline-main-bcg-item-perex-color;
        }
        &-btn{
          color: $timeline-main-bcg-item-link-color;
        }
        &:after{
          background: $timeline-main-bcg-bullet-background;
          border-color: $timeline-main-bcg-bullet-border;
        }
      }
    }
  }
}

//* timeline end *//

//* rolldown *//

.collapsible-item  {
  border-color: $rolldown-border-color;
  &-index {
    background-color: $rolldown-index-background;
    color: $rolldown-index-color;
    &:before{
      color: $rolldown-ico-color;
    }
  }
  &:last-child{
    border:1px solid $rolldown-border-color;
  }
  .collapsible-item-text{
    background-color: $base-white;
  }
}

.collapsible-item-heading{
   background: $rolldown-head-background;
    &:after{
    color: rgba($rolldown-arrow-color, 0.5);
  }
  &.open,
  &:hover{
    background: $rolldow-hover-background;
    color:$rolldow-hover-color;
    &:after{
      color:$rolldown-arrow-hover-color;
    }
  }
  &.open{
    border-bottom:1px solid $rolldown-border-color;
  }
}

//* rolldown end *//

//* tenders table *//

main{
  .tenders-table{
    table{
      thead{
        tr{
          th{
            color: $download-table-header-color;
          }
        }
      }
      tbody{
        tr{
          background: $download-table-body-background;
          border-bottom-color:  $download-table-border-color;
          color: $text-color;
          &:hover{
            background: $download-table-tr-hover;
          }
          td{
            border-bottom-color: $download-table-border-color;
          }
          td a{
            color: $download-table-download-color;
          }
        }
      }
    }
  }

  .bgc-gray{
    .tenders-table{
      table{
        thead{
          tr{
            th{
              color: $download-table-grey-header-color;
            }
          }
        }
        tbody{
          tr{
            background: $download-table-grey-body-background;
            border-bottom: 1px solid $download-table-grey-border-color;
            color: $text-color;
            &:hover{
              background: $download-table-grey-tr-hover;
            }
            td a{
              color: $download-table-grey-download-color;
            }
          }
        }
      }
    }
  }

  .bgc-main{
    .tenders-table{
      table{
        thead{
          tr{
            th{
              color: $download-table-main-header-color;
            }
          }
        }
        tbody{
          tr{
            background: $download-table-main-body-background;
            border-bottom: 1px solid $download-table-main-border-color;
            color: $text-color;
            &:hover{
              background: $download-table-main-tr-hover;
            }
            td a{
              color: $download-table-main-download-color;
            }
          }
        }
      }
    }
  }
}

main .second-table{
  .tenders-table{
    thead{
      tr th{
        color: #b6b6b6 !important;
      }
    }
     tbody{
       tr{
         background: $base-white;
         &:hover{
           background: #FAFAFA;
         }
       }
       tr:after,
       tr td:after{
         background: $base-grey;
       }
       tr td span{
         background: $main-color;
       }
     }
     .btn-download{
       color: $base-black;
       &:focus,
       &:hover{
         color: $main-color;
       }
     }
  }
}

//* tenders end *//

//* download tiles *//

.download-item{
  &-ico{
    &:before{
      color: $donwload-tile-link-color;
    }
  }
  .download-item-content{
    &-date{
      color: $download-tile-date-color;
    }
    &-name{
      color: $download-tile-name-color;
    }
    &-link{
      color: $donwload-tile-link-color;
      a{
        color: $donwload-tile-link-color;
      }
    }
  }
  &:focus,
  &:hover{
    .download-item-content-link{
      a{
        color: $donwload-tile-link-color;
      }
    }
  }
}

.bgc-gray{
  .download-item{
    &-ico{
      span:before{
        color: $donwload-tile-grey-link-color;
      }
      span{
          &.path5,
          &.path6,
          &.path7 {
              &:before{
                  color: $donwload-tile-grey-ico-text-color;
              }
          }
      }
    }
    .download-item-content{
      &-date{
        color: $download-tile-grey-date-color;
      }
      &-name{
        color: $download-tile-grey-name-color;
      }
      &-link{
        color: $donwload-tile-grey-link-color;
        a{
          color: $donwload-tile-grey-link-color;
        }
      }
    }
    &:focus,
    &:hover{
      .download-item-content-link{
        a{
          color: $donwload-tile-grey-link-color;
        }
      }
    }
  }
}

.bgc-main{
  .download-item{
    &-ico{
      span:before{
        color: $donwload-tile-main-link-color;
      }
      span{
          &.path5,
          &.path6,
          &.path7 {
              &:before{
                  color: $donwload-tile-main-ico-text-color;
              }
          }
      }
    }
    .download-item-content{
      &-date{
        color: $download-tile-main-date-color;
      }
      &-name{
        color: $download-tile-main-name-color;
      }
      &-link{
        color: $donwload-tile-main-link-color;
        a{
          color: $donwload-tile-main-link-color;
        }
      }
    }
    &:focus,
    &:hover{
      .download-item-content-link{
        a{
          color: $donwload-tile-main-link-color;
        }
      }
    }
  }
}

//* download tiles end*//

//* quotation *//

.citation-item{
  &-content{
    &-text{
      color: $quotation-text-color;
    }
    &-author{
      color: $quotation-author-color;
    }
  }
}

.bgc-gray{
  .citation-item{
    &-content{
      &-text{
        color: $quotation-grey-text-color;
      }
      &-author{
        color: $quotation-grey-author-color;
      }
    }
  }
}

.bgc-main{
  .citation-item{
    &-content{
      &-text{
        color: $quotation-main-text-color;
      }
      &-author{
        color: $quotation-main-author-color;
      }
    }
  }
}

//* quotation end*//

//* icons *//

.icons-container{
  .ico-card-item-small,
  .ico-card-item{
    &-icon {
      &:before {
        color: $icons-icon-color;
        //background-color: $icons-icon-background;
      }
    }
    &-title{
      color: $icons-icon-title-color;
    }
    &-perex{
      color: $icons-icon-perex-color;
    }
  }
}

.bgc-gray{
  .icons-container{
    .ico-card-item-small,
    .ico-card-item{
      &-icon {
        &:before {
          color: $icons-grey-background-icon-color;
          //background-color: $icons-grey-background-icon-background;
        }
      }
      &-title{
        color: $icons-grey-background-title-color;
      }
      &-perex{
        color: $icons-grey-background-perex-color;
      }
    }
  }
}

.bgc-main{
  .icons-container{
    .ico-card-item-small,
    .ico-card-item{
      &-icon{
        &:before {
          color: $icons-main-background-icon-color;
          //background-color: $icons-main-background-icon-background;
        }
      }
      &-title{
        color: $icons-main-background-title-color;
      }
      &-perex{
        color: $icons-main-background-perex-color;
      }
    }
  }
}

//* person *//
.person-item {
  &-image {
    &.man,
    &.woman {
      background-color: $person-image-background;
    }
  }
  &-content{
    &-function,
    &-name{
      color: $person-text-color;
    }
  }
}

.person-item .person-item-inner{
  .person-item-content-name{
    color: $main-color;
  }
}

.person-table{
  tr{
    &:hover{
      background: #f5f5f5 !important;
    }
  }
}

.table-small{
  table{
    .person-small-item-top{
      background: $main-color !important;
      color: $person-small-top-color;
    }
    .person-small-item-bottom{
      background: $base-white !important;
      color: $text-color;
    }
  }
}

.bgc-gray{
  .person-item {
    &-image {
      &.man,
      &.woman {
        background-color: $person-grey-bcg-image-background;
      }
    }
    &-content{
      &-function,
      &-name{
        color: $person-grey-bcg-text-color;
      }
    }
  }

  .person-small-item{
    td {
      border-bottom: 1px solid $background-grey;
    }
    &:nth-child(odd) {
      background-color:$person-main-bcg-small-even-background;
    }
    &:nth-child(even) {
      background-color:$person-main-bcg-small-even-background;
    }
    @include media($respo-table-break) {
      td {
        border-bottom: none;
      }
      &:nth-child(odd) {
        background-color: $person-main-bcg-small-odd-background;
      }
      &:nth-child(even) {
        background-color: $person-main-bcg-small-even-background;
      }
    }
  }
}

.person-small-item{
  td {
    border-bottom: 1px solid $base-white;
    &:first-child {
      background-color: $person-small-respo-header-background;
      border-bottom: none;
      color: $person-small-respo-header-color;
    }
    &:nth-child(2) {
      background-color: lighten($person-small-respo-header-background, 10);
      color: $person-small-respo-header-color;
    }
  }
  &:nth-child(odd) {
    background-color:$person-main-bcg-small-even-background-white-container;
  }
  &:nth-child(even) {
    background-color:$person-main-bcg-small-even-background-white-container;
  }
  @include media($respo-table-break) {
    td {
      border-bottom: none;
      &:first-child {
        background-color: transparent;
        color: $person-text-color;
      }
      &:nth-child(2) {
        background-color: transparent;
        color: $person-text-color;
      }
    }
    &:nth-child(odd) {
      background-color: $person-main-bcg-small-odd-background;

    }
    &:nth-child(even) {
      background-color: $person-main-bcg-small-even-background-white-container;
    }
  }
}

.bgc-main{
  .person-item {
    &-image {
      &.man,
      &.woman {
        background-color: $person-main-bcg-image-background;
      }
    }
    &-content{
      &-function,
      &-name{
        color: $person-main-bcg-text-color;
      }
    }
  }
}

//* person end *//

//* important *//

.important-item{
  background: $important-background;
  color:$important-color;
  &-label{
    background: $important-label-background;
    color: $important-label-color;
  }
  .link{
    color:$important-color;
  }
  &:focus,
  &:hover{
    color: $important-color;
    box-shadow: 0 10px 30px rgba($important-hover-shadow, 0.33);
  }
}

.bgc-main{
  .important-item{
    background: $important-main-background;
    color:$important-main-color;
    &-label{
      background: $important-main-label-background;
      color: $important-main-label-color;
    }
    .link{
      color:$important-main-color;
    }
    &:focus,
    &:hover{
      color: $important-main-color;
      box-shadow: 0 10px 30px rgba($important-main-hover-shadow, 0.5);
    }
  }
}

//* important end *//

//* product *//

.product-item{
  &:before{
    background: linear-gradient(to top,rgba($product-gradient-start, 0.5) 0%,transparent 50%);
  }
   &-inner{
    &-title{
      color: $product-title-color;
      @include background-opacity($product-label-background, 0.75);
    }
    &-btn{
      background: $product-reverse-background;
      @include background-opacity($product-reverse-background, 0.9);
      color: $product-reverse-color;
    }
  }
}

.category-label{
  @include background-opacity($product-label-background, 0.75);
  color: $product-label-color;
}

//* product end *//

//* event *//

.events-box{
  .events-box-left,
  .events-box-right{
    h3{
      color:$event-box-title-upcoming;
    }
    .event-line{
      background: $event-box-title-upcoming;
      &:after{
        border-right:10px solid $event-box-title-upcoming;
      }
    }
    .event-respo-title{
      border-bottom-color: $event-box-title-upcoming;
    }
    &.past{
      h3{
        color:$event-box-title-past;
      }
      .event-line{
        background: $event-box-title-past;
        &:after{
          border-right:10px solid $event-box-title-past;
        }
      }
      .event-respo-title{
        border-bottom-color: $event-box-title-past;
      }
    }
  }
}

.event-item{
  color: $event-color;
  h3{
    color: $event-title-color !important;
  }
  &-content{
    &-label{
      background: $event-label-background;
      color:$event-label-color;
    }
    &-link{
      .link{
        color: $event-title-color;
      }
    }
  }
  &-from,
  &-label{
    background: rgba($event-label-background, 0.8);
    color:$event-label-color;
  }
  &-date{
    &-inner{
      background: $event-date-background;
      color:$event-date-color;
    }
  }
  &:focus,
  &:hover{
    color: $event-color;
    .event-item-date-inner{
      background: darken($event-date-background, 10);
    }
  }
}

.event-item-new-name{
  color: $base-black;
}

.event-item-new{
  border-color: $color-line;
  background-color: $base-white;
  .event-item-time{
    color: #595959;
  }
  &:hover{
    .event-item-new-name{
      color: $main-color;
    }
  }
}

.event-item-new-date{
  color: $main-color;
  &:before{
    color: $base-black;
  }
}


//* event end *//

//* card box *//

.card-box{
  .card-item{
    color:$cart-box-color;
    h3{
      color:$cart-box-color;
    }
    &:after{
      background: linear-gradient(0deg,
      rgba($main-gradient-after,1) 0%,
      rgba($main-gradient-after,0.44) 25%,
      rgba($main-gradient-after,0.33) 50%,
      rgba($main-gradient-after,0.22) 75%,
      rgba(255,255,255,0.11) 100%);
    }
    &:before{
      background: linear-gradient(0deg,
      rgba($main-gradient-before,1) 0%,
      rgba($main-gradient-before,0.52) 25%,
      rgba($main-gradient-before,0.2) 50%,
      rgba($main-gradient-before,0.02) 75%,
      rgba($main-gradient-before,0) 100%);
    }
    &-content-ico{
      &:before{
        color:$cart-box-color;
      }
    }
    .link{
      color:$cart-box-color;
    }
    &:focus,
    &:hover{
      color:$cart-box-color;
      .link{
        color:$cart-box-color;
      }
    }
  }
}

body{
  &.contrast{
    .card-box{
      .card-item{
        &:before{
          opacity: 1;
          transform: scale(1.25);
          background: linear-gradient(to top,rgba($main-contrast-gradient, 1) 0%, transparent 50%);
        }
        &:after{
          display: none;
        }
      }
    }
  }
}

//* card box end *//

//* card box mini *//


.card-item-mini{
  &:after{
    border-left-color: $card-mini-border-color;
  }
  h3{
    color:$card-mini-title-color;
  }
  .link{
    color: $card-mini-link-color;
  }
  &-ico{
    &:before{
      color:$card-mini-ico-color;
    }
  }
}

.bgc-main{
  .card-item-mini{
    h3{
      color:$card-mini-main-color;
    }
    .link{
      color: $card-mini-main-color;
    }
    &-ico{
      &:before{
        color:$card-mini-main-color;
      }
    }
  }
}

//* card box mini end *//

//* card box small *//


.card-item-small{
  color: $card-small-color;
  &-ico{
    &:before{
      color:$card-small-ico-color;
    }
  }
  h3{
    color: $card-small-title-color;
  }
  .link{
    color: $card-small-link-color;
  }
  &:focus,
  &:hover{
    color: $card-small-color;
  }
}

.bgc-main{
  .card-item-small{
    h3{
      color:$card-small-main-color;
    }
    .link{
      color: $card-small-main-color;
    }
    &-ico{
      &:before{
        color:$card-small-main-color;
      }
    }
  }
}

//* card box small end *//

//* card box big *//

.card-box-big{
  .card-item-big{
    color: $card-big-color;
    background-color: $card-big-background-color;
    .card-item-big-header-type {
      color: $card-big-title-color;
      background-color: $card-big-type-background;
    }
    h3{
      color: $card-big-title-color;
    }
    .link{
      color: $card-big-link-color;
    }
    .card-item-big-content-top{
      color:$card-big-top-color;
    }
    .card-item-big-content-ico{
      &:before{
        color: $card-big-color;
      }
    }
    &:focus,
    &:hover{
      color: $card-big-color;
    }
  }
}

.bgc-gray {
  .card-item-big {
    background-color: $card-big-background-color-gray-box;
  }
}

.bgc-main{
  .event-item{
    color: $card-big-main-color;
  }
  .event-item h3{
    color: $card-big-main-title-color !important;
  }
  .event-item-content-link .link{
    color: $card-big-main-title-color;
  }
}

//* card box big end *//

//* video *//

.embed-video-icon{
  background: $video-play-background;
  &:before{
    border-left-color: $video-play-color;
  }
}

.embed-video{
  &:focus,
  &:hover{
    .embed-video-icon{
      &:before{
        border-left-color: darken($video-play-color,10);
      }
    }
  }
}

//* video end *//

//* number listing *//

.numbers-container {
  &:before{
    background-color: $container-white-background;
    color: $number-background-icon;
  }
  .number-item {
    background-color: $number-item-background;

    &-number {
      color: $number-background;
      border-color: $number-background;
    }
    &-ico{
      color: $number-background;
    }
    &-perex{
      color: $text-color;
    }
  }
}

.bgc-gray{
  .numbers-container {

    .number-item {
      background-color: $number-item-grey-background;

      &-number {
        color: $number-grey-background;
        border-color: $number-grey-background;
      }
      &-ico{
        color: $number-grey-background;
      }
    }
  }
}

.bgc-main{
  .numbers-container {

    .number-item {
      background-color: $number-item-main-background;
      h2,
      h3{
        color: $number-main-title-color;
      }
      &-number {
        color: $number-main-background;
        border-color: $number-main-background;
      }
      &-ico{
        color: $number-main-background;
      }
      ul li:before{
        background: $number-main-title-color;
      }
      ol li:before{
        color: $number-main-title-color;
      }
    }
  }
}


//* number listing end *//

//* logo *//

.logo-carousel{
  .slick-arrow{
    color: $logo-arrow-color;
    &.slick-disabled{
      color: $logo-arrow-disable-color;
    }
  }
}

//* logo end *//

//* CTA *//

.cta-block-big{
  h3,
  h3{
    color: $big-cta-title-color
  }
}

.cta-block-big-inner-left{
  &:after{
    border-left-color: $big-cta-border-color;
  }
}

.bgc-main{
  .cta-block-big{
    color: $big-cta-patter-text-color;
    h3,
    h3{
      color: $big-cta-patter-title-color;
    }
  }
  .cta-block-big-inner-left{
    &:after{
      border-left-color: $big-cta-patter-border-color;
    }
  }
}

//* CTA end *//

//* slider *//

.main-slider{
  .slider-founder,
  h1,
  h2,
  h3{
    color: $slider-title-color;
  }
  .slick-arrow{
    color:$slider-arrow-color;
    &:focus,
    &:hover{
      color: $slider-arrow-hover-color;
    }
  }
}

.slider-container.slider-two{
  .main-slider h2,
  .h1,
  h1{
    background: $main-color;
    box-shadow:
      15px 0 0 $main-color,
      -15px 0 0 $main-color;
  }
  .main-slider-content h3{
    background: $main-color;
    box-shadow:
      15px 0 0 $main-color,
      -15px 0 0 $main-color;
  }
}

.slider-service-static,
.slider-service{
  background: $slider-service-background;
  @include media(lg){
    background: rgba($slider-service-background, 0.9);
  }
  .card-item-mini h3,
  .card-item-mini .link,
  .card-item-mini-ico{
    color: $slider-service-color;
  }
  .card-item-mini-ico{
    &:before{
      color: $slider-service-color;
    }
  }
  .line-helper{
    &:before{
      border-left-color: $slider-service-line-helper-border-color;
    }
  }
}

.slider-service-static{
  background: $slider-service-background;
}

body{
  &.contrast{
    .slider-service-static,
    .slider-service{
      background: rgba($main-contrast, 0.9);
    }
  }
}

.slider-grey{
  background: $slider-grey-background;
  @include media(lg){
    background: none;
  }
}

.slider-grey-icon{
  background: $slider-grey-background;
}

.slider-grey-inner{
  @include media(lg){
    background-image: linear-gradient(to top, rgba($slider-grey-background, 1) 0%, rgba($slider-grey-background, 0.9) 80%, rgba($slider-grey-background, 0.8) 100%);
  }
}

.slider-grey-item{
  color: $slider-grey-item-link-color;
  &-date{
    color:$slider-grey-item-date-color;
  }
  &:focus,
  &:hover{
    color: $slider-grey-item-link-color;
  }
  .link{
    &:focus,
    &:hover{
      color: $slider-grey-item-link-color;
    }
  }
}

//* slider end *//

//* empty image background *//

.event-item-image-bgc,
.card-item-big-image-bgc{
  background: $empty-image-background;
}

//* empty image background end *//

//* mapa *//

.marker-detail-subtitle{
  color: $main-color;
}

.marker-detail-service,
.marker-detail-capacity,
.marker-detail-contact,
.marker-detail-mail,
.marker-detail-content{
  color: $map-marker-color;
}

/*.marker-detail-name{
  color: $main-color !important;
}*/

.bgc-main{
  .marker-detail-inner {
    color: $map-marker-color;
    h4{
      color: $main-color !important;
    }
  }
}

//* mapa end *//

//* gallery *//

.widget-container{
  .gallery-title{
    &:before{
      background: $gallery-title-line-background;
    }
  }
}

//* gallery end *//

//* social worker *//

.street-filter-list{
  a{
    color: #000;
  }
  a.street-filter-disabled {
    color: grey;
  }
  a.active{
    color: $main-color;
  }
}

.street-container{
  li{
    &:before{
      background: #000;
    }
  }
}

//* social worker end *//

//* branches *//

.branches-box{
  background: #fff;
}

.branch-item{
  border-color: #E5E2E2;
  h3{
    color: #000;
  }
  &.active{
    border-color: darken($main-color, 10);
    background-color: rgba($main-color, 0.2);
  }
  a{
    color: $main-color;
    &:before{
      color: $main-color;
    }
  }
  &-address:before,
  &-info:before{
    color: $main-color;
  }
}

.branches-detail-position{
  .gm-style-iw{
    background-color: rgba(#6d2a18, 0.9);
    color: #fff;
    a{
      color: #fff;
    }
  }
}

//* branches end *//

// * small crosslink **//

.crosslink-item{
  &.color-one{
    background: $background-one;
    color: $color-one;
    .link{
      color: $link-color-one;
    }
    &:focus,
    &:hover{
      color: $color-one;
      .link{
        color: darken($link-color-one, 5);
      }
    }
  }
  &.color-two{
    background: $background-two;
    color: $color-two;
    .link{
      color: $link-color-two;
    }
    &:focus,
    &:hover{
      color: $color-two;
      .link{
        color: darken($link-color-two, 5);
      }
    }
  }
  &.color-three{
    background: $background-three;
    color: $color-three;
    .link{
      color: $link-color-three;
    }
    &:focus,
    &:hover{
      color: $color-three;
      .link{
        color: darken($link-color-three, 5);
      }
    }
  }
  &.color-four{
    background: $background-four;
    color: $color-four;
    .link{
      color: $link-color-four;
    }
    &:focus,
    &:hover{
      color: $color-four;
      .link{
        color: darken($link-color-four, 5);
      }
    }
  }
  &.color-five{
    background: $background-five;
    color: $color-five;
    .link{
      color: $link-color-five;
    }
    &:focus,
    &:hover{
      color: $color-five;
      .link{
        color: darken($link-color-five, 5);
      }
    }
  }
  &.color-six{
    background: $background-six;
    color: $color-six;
    .link{
      color: $link-color-six;
    }
    &:focus,
    &:hover{
      color: $color-six;
      .link{
        color: darken($link-color-six, 5);
      }
    }
  }
}

.image-content-item{
  &.reverse-item{
    .image-content-item-right{
      @include media(sm){
        background-color: $base-grey;
      }
    }
  }
}

// * small crosslink end **//

.slider-new.slider-second{
  .slider-grey-item{
    color: $base-white;
    background: rgba($second-color, 0.9);
    .link{
      color: $base-white;
    }
  }
  .slider-grey-item-date{
    color: $base-white;
  }
  .slider-grey-box:first-child{
    .slider-grey-item{
      background: $main-color;
    }
  }
  .slider-founder{
    background-color: rgba($main-color, 0.9);
    color: $base-white;
  }
  .main-slider-text{
    background-color: rgba($second-color, 0.9);
    color: $base-white;
    h1,h2{
      color: $base-white;
    }
  }
  .btn-primary,
  .btn-default{
    background-color: $base-white;
    color: $base-black;
    &:focus,
    &:hover{
      background-color: $base-black;
      color: $base-white;
    }
  }
  .slider-grey{
    background-color: $base-grey;
    @include media(lg){
      background: none;
    }
  }
}

.slider-new.slider-third{
  .slider-grey-item{
    color: $base-white;
    background: rgba($second-color, 0.9);
    .link{
      color: $base-white;
    }
  }
  .slider-grey-box:first-child{
    .slider-grey-item{
      background: rgba($main-color, 0.9);
    }
  }
  .slider-grey-item-date{
    color: $base-white;
  }
  .main-slider-content{
    &:before{
      background: transparent linear-gradient(57deg, rgba($second-color, 0.9) 0%, rgba($main-color, 0.9) 100%) 0% 0% no-repeat padding-box;  
    }
  }
  .main-slider-design-helper{
    &:before,
    &:after{
      background-color: $base-white;
    }
  }
  .btn-primary,
  .btn-default{
    background-color: $base-white;
    color: $base-black;
    &:focus,
    &:hover{
      background-color: $base-black;
      color: $base-white;
    }
  }
  .main-slider-text{
    color: $base-white;
    h1,h2{
      color: $base-white;
    }
  }
  .slider-grey{
    background-color: $base-grey;
    @include media(lg){
      background: none;
    }
  }
}

.slider-new{
  .slick-dots button{
    background-color: $second-color;
  }
  .slick-dots .slick-active button{
    background-color: $main-color;
  }
  .slick-dots button:focus{
    border: 2px solid $base-white;
  }
}

// * wizard **//

.wizard-menu{
  &-link{
    color:$text-color;
    &:after{
      background: $color-line;
      @include media(sm){
        background: $main-color;
      }
    }
    &:last-child{
      margin-right: none;
    }
    &:hover{
      color: $wizard-active;
      span{
        background: $wizard-active;
        color: $base-white;
      }
    }
    &:focus,
    &.active{
      color:$wizard-active;
      &:after{
        background: $wizard-active;
      }
      span{
        background: $wizard-active;
        color: $base-white;
      }
    }
    &.disabled{
      color: $wizard-grey;
    }
  }
}

.wizard-container{
  .wizard-line{
    background-color: $main-color;
  }
  .form-item.border-left{
    &:before{
      background-color: $main-color;
    }
  }
}

.bgc-white{
  .wizard-container{
    background-color: $container-grey-background;  
    .selectric{
      background: $selectric-grey-background;
    }
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"]{
      background: $input-grey-background;
    }
    .file-placeholder{
      background: $input-grey-background;
    }
    .checkbox input[type="checkbox"] + label:before{
      background: $input-grey-background;
      color: $input-grey-background;
    }
    .radio input[type="radio"] + label:before{
      border-color: $input-grey-background;
      background: $input-grey-background;
    }
    .checkbox input[type="checkbox"]:checked + label:before{
      color: $main-color;
    }
    .radio input[type="radio"]:checked + label:before {
        background: $main-color;
    }
  }
}

.bgc-main .wizard-container,
.bgc-gray .wizard-container,
.bgc-dark .wizard-container{
  background-color: $base-white;
  .wizard-top{
    background-color: $base-white;
  }
  .wizard-close{
    background-color: $base-white;
    color: $main-color;
  }
  ol{
    color: $text-color;
    li:before{
      color: $wizard-active;
    }
  }
  .wizard-menu{
    @include media(xl){
      border-bottom-color:$wizard-line;
    }
    &-link{
      span{
        background: $wizard-line;
        color: $text-color;
      }
      &.disabled{
        color: $wizard-grey;
        pointer-events: none;
        span{
          color: $wizard-grey;
        }
      }
      &:hover{
        color: $wizard-active;
        span{
          background: $wizard-active;
          color: $base-white;
        }
      }
      &:focus,
      &.active{
        color:$wizard-active;
        &:after{
          background: $wizard-active;
        }
        span{
          background: $wizard-active;
          color: $base-white;
        }
      }
    }
  }
  .wizard-bottom{
    border-color: $wizard-line;
  }
  .form-section{
    border-color: $wizard-line;
  }
  .wizard-section-title,
  label,
  .wizard-step-subtitle,
  .EditingFormLabel,
  .label-title,
  .personal-detail-text,
  .wizard-text {
    color: $text-color;
  }
  .wizard-step-title,
  .wizard-step-subtitle{
    color: $wizard-active;
  }
  .form-control, select, select.form-control, textarea, textarea.form-control, input[type="text"], .selectric{
    border-color: $wizard-input-border;
    @include placeholder{
      color: $wizard-placeholder;
    }
  }
  .checkbox input[type="checkbox"] + label:before{
    background: $input-background;
    color: $input-background;
  }
  .radio input[type="radio"] + label:before{
    border-color: $base-white;
    background: $base-white;
    box-shadow: 0 0 0 2px $main-color;
  }
  .checkbox input[type="checkbox"]:checked + label:before{
    color: $main-color;
  }
  .radio input[type="radio"]:checked + label:before {
      background: $main-color;
  }
  .wizard-step-desc{
    color: $wizard-input-border;
  }
  .line{
    background-color: $wizard-line;
  }
  .btn.disabled{
    background-color: grey;
    opacity: 0.7;
  }
  .wizard-top{
    border-right-color: $wizard-line;
  }

  ul li{
    &:before{
      background-color: $main-color;
    }
  }
}

.wizard-download-item{
  background-color: #f8f9fa;
  color: #727f7f;
  .wizard-download-item-size{
    color: #929495;
  }
  &:after{
    border-color: $main-color;
    color: $main-color;
  }
  &:focus,
  &:hover{
    background-color: $main-color;
    color: $base-white;
    .wizard-download-item-size{
      color: $base-white;
    }
    &:after{
      border-color: $base-white;
      color: $base-white;
    }
    .path1,
    .path2,
    .path3{
      &:before{
        color: $base-white;
      }
    }
  }
}

.bgc-main .wizard-container .Error .radio input[type="radio"]+label:before, 
.bgc-gray .wizard-container .Error .radio input[type="radio"]+label:before, 
.bgc-dark .wizard-container .Error .radio input[type="radio"]+label:before{
  box-shadow: 0 0 0 2px $error-input-border;
}

// * wizard end **//