.page-map {
    a {
      color: $page-map-link-color;
      &:hover,
      &:focus {
        color: darken($page-map-link-color, 10);
      }
    }

  .page-map-column {
    >ul {
      >li > a ,
      >li > span {
        color: $page-map-first-level-color;
      }
      >li > a  {
        &:hover,
        &:focus {
          color: darken($page-map-first-level-color, 10);
        }
      }

    }
  }
}
