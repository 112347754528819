
.header-cross{
  background: $header-cross-background;
  border-bottom-color: $header-cross-border-color;
  &-link{
    background: $header-cross-link-background;
    &.style-home{
      background: $header-cross-home-background;
      color:$header-cross-home-color;
      border-bottom-color: $header-cross-border-color;
    }
    &.style-one{
      border-bottom-color: $header-cross-style-one-border-color;
      color: $header-cross-style-one-color;
      &:after{
        border-bottom-color: $header-cross-style-one-border-color;
      }
      &:focus,
      &:hover,
      &.active{
        color: $header-cross-style-one-active-color;
        background: $header-cross-style-one-hover;
      }
    }
    &.style-two{
      border-bottom-color: $header-cross-style-two-border-color;
      color: $header-cross-style-two-color;
      &:after{
        border-bottom-color: $header-cross-style-two-border-color;
      }
      &:focus,
      &:hover,
      &.active{
        color: $header-cross-style-two-active-color;
        background: $header-cross-style-two-hover;
      }
    }
    &.style-three{
      border-bottom-color: $header-cross-style-three-border-color;
      color: $header-cross-style-three-color;
      &:after{
        border-bottom-color: $header-cross-style-three-border-color;
      }
      &:focus,
      &:hover,
      &.active{
        color: $header-cross-style-three-active-color;
        background: $header-cross-style-three-hover;
      }
    }
    &.style-four{
      border-bottom-color: $header-cross-style-four-border-color;
      color: $header-cross-style-four-color;
      &:after{
        border-bottom-color: $header-cross-style-four-border-color;
      }
      &:focus,
      &:hover,
      &.active{
        color: $header-cross-style-four-active-color;
        background: $header-cross-style-four-hover;
      }
    }
    &.style-five{
      border-bottom-color: $header-cross-style-five-border-color;
      color: $header-cross-style-five-color;
      &:after{
        border-bottom-color: $header-cross-style-five-border-color;
      }
      &:focus,
      &:hover,
      &.active{
        color: $header-cross-style-five-active-color;
        background: $header-cross-style-five-hover;
      }
    }
  }
}

.style-one{
  header{
    background: $header-cross-style-one-header-background;
  }
  .menu-openner:before,
  header .header-menu ul li > span,
  header .header-menu ul li > a,
  .header-menu a:before,
  .open-search,
  .header-email,
  .header-phone{
    color: $header-cross-style-one-header-color;
  }
}

.style-two{
  header{
    background: $header-cross-style-two-header-background;
  }
  .menu-openner:before,
  header .header-menu ul li > span,
  header .header-menu ul li > a,
  .header-menu a:before,
  .open-search,
  .header-email,
  .header-phone{
    color: $header-cross-style-two-header-color;
  }
}

.style-three{
  header{
    background: $header-cross-style-three-header-background;
  }
  .menu-openner:before,
  header .header-menu ul li > span,
  header .header-menu ul li > a,
  .header-menu a:before,
  .open-search,
  .header-email,
  .header-phone{
    color: $header-cross-style-three-header-color;
  }
}

.style-four{
  header{
    background: $header-cross-style-four-header-background;
  }
  .menu-openner:before,
  header .header-menu ul li > span,
  header .header-menu ul li > a,
  .header-menu a:before,
  .open-search,
  .header-email,
  .header-phone{
    color: $header-cross-style-four-header-color;
  }
}

.style-five{
  header{
    background: $header-cross-style-five-header-background;
  }
  .menu-openner:before,
  header .header-menu ul li > span,
  header .header-menu ul li > a,
  .header-menu a:before,
  .open-search,
  .header-email,
  .header-phone{
    color: $header-cross-style-five-header-color;
  }
}

.cross-card-item-image{
  &:before{
    background-color: $base-black;
    opacity: 0.25;
  }
}

.cross-card-item{
  &.style-one{
    .cross-card-item-image{
      &:after{
        background: linear-gradient(0deg,
        rgba($style-one-color,1) 0%,
        rgba($style-one-color,0.44) 25%,
        rgba($style-one-color,0.33) 50%,
        rgba($style-one-color,0.22) 75%,
        rgba(255,255,255,0.11) 100%);
      }
    }
    .cross-card-item-bottom{
      background: $style-one-color;
    }
    .btn-cross{
      background: $cross-item-card-style-one-btn-background;
      color: $cross-item-card-style-one-btn-color;
      &:focus,
      &:hover{
          text-decoration: none;
          background: darken($cross-item-card-style-one-btn-background, 10);
          color: $cross-item-card-style-one-btn-color;
      }
    }
    h2{
      color: $cross-item-card-style-one-title-color;
    }
    .card-item-mini{
      h3{
        color:$cross-item-card-style-one-btn-color;
      }
      .link{
        color: $cross-item-card-style-one-btn-color;
      }
      &-ico{
        &:before{
          color:$cross-item-card-style-one-btn-color;
        }
      }
    }
  }
  &.style-two{
    .cross-card-item-image{
      &:after{
        background: linear-gradient(0deg,
        rgba($style-two-color,1) 0%,
        rgba($style-two-color,0.44) 25%,
        rgba($style-two-color,0.33) 50%,
        rgba($style-two-color,0.22) 75%,
        rgba(255,255,255,0.11) 100%);
      }
    }
    .cross-card-item-bottom{
      background: $style-two-color;
    }
    .btn-cross{
      background: $cross-item-card-style-two-btn-background;
      color: $cross-item-card-style-two-btn-color;
      &:focus,
      &:hover{
          text-decoration: none;
          background: darken($cross-item-card-style-two-btn-background, 10);
          color: $cross-item-card-style-two-btn-color;
      }
    }
    h2{
      color: $cross-item-card-style-two-title-color;
    }
    .card-item-mini{
      h3{
        color:$cross-item-card-style-two-btn-color;
      }
      .link{
        color: $cross-item-card-style-two-btn-color;
      }
      &-ico{
        &:before{
          color:$cross-item-card-style-two-btn-color;
        }
      }
    }
  }
  &.style-three{
    .cross-card-item-image{
      &:after{
        background: linear-gradient(0deg,
        rgba($style-three-color,1) 0%,
        rgba($style-three-color,0.44) 25%,
        rgba($style-three-color,0.33) 50%,
        rgba($style-three-color,0.22) 75%,
        rgba(255,255,255,0.11) 100%);
      }
    }
    .cross-card-item-bottom{
      background: $style-three-color;
    }
    .btn-cross{
      background: $cross-item-card-style-three-btn-background;
      color: $cross-item-card-style-three-btn-color;
      &:focus,
      &:hover{
          text-decoration: none;
          background: darken($cross-item-card-style-three-btn-background, 10);
          color: $cross-item-card-style-three-btn-color;
      }
    }
    h2{
      color: $cross-item-card-style-three-title-color;
    }
    .card-item-mini{
      h3{
        color:$cross-item-card-style-three-btn-color;
      }
      .link{
        color: $cross-item-card-style-three-btn-color;
      }
      &-ico{
        &:before{
          color:$cross-item-card-style-three-btn-color;
        }
      }
    }
  }
  &.style-four{
    .cross-card-item-image{
      &:after{
        background: linear-gradient(0deg,
        rgba($style-four-color,1) 0%,
        rgba($style-four-color,0.44) 25%,
        rgba($style-four-color,0.33) 50%,
        rgba($style-four-color,0.22) 75%,
        rgba(255,255,255,0.11) 100%);
      }
    }
    .cross-card-item-bottom{
      background: $style-four-color;
    }
    .btn-cross{
      background: $cross-item-card-style-four-btn-background;
      color: $cross-item-card-style-four-btn-color;
      &:focus,
      &:hover{
          text-decoration: none;
          background: darken($cross-item-card-style-four-btn-background, 10);
          color: $cross-item-card-style-four-btn-color;
      }
    }
    h2{
      color: $cross-item-card-style-four-title-color;
    }
    .card-item-mini{
      h3{
        color:$cross-item-card-style-four-btn-color;
      }
      .link{
        color: $cross-item-card-style-four-btn-color;
      }
      &-ico{
        &:before{
          color:$cross-item-card-style-four-btn-color;
        }
      }
    }
  }
  &.style-five{
    .cross-card-item-image{
      &:after{
        background: linear-gradient(0deg,
        rgba($style-five-color,1) 0%,
        rgba($style-five-color,0.44) 25%,
        rgba($style-five-color,0.33) 50%,
        rgba($style-five-color,0.22) 75%,
        rgba(255,255,255,0.11) 100%);
      }
    }
    .cross-card-item-bottom{
      background: $style-five-color;
    }
    .btn-cross{
      background: $cross-item-card-style-five-btn-background;
      color: $cross-item-card-style-five-btn-color;
      &:focus,
      &:hover{
          text-decoration: none;
          background: darken($cross-item-card-style-five-btn-background, 10);
          color: $cross-item-card-style-five-btn-color;
      }
    }
    h2{
      color: $cross-item-card-style-five-title-color;
    }
    .card-item-mini{
      h3{
        color:$cross-item-card-style-five-btn-color;
      }
      .link{
        color: $cross-item-card-style-five-btn-color;
      }
      &-ico{
        &:before{
          color:$cross-item-card-style-five-btn-color;
        }
      }
    }
  }
}

.main-crosslink-item{
  color: $main-crosslink-item-color;
  &.style-one{
    background: $style-one-color;
    &:before{
      border-bottom-color: $style-one-color;
    }
    &:focus,
    &:hover{
      background: darken($style-one-color, 10);
      &:before{
        border-bottom-color: darken($style-one-color, 10);
      }
    }
  }
  &.style-two{
    background: $style-two-color;
    &:before{
      border-bottom-color: $style-two-color;
    }
    &:focus,
    &:hover{
      background: darken($style-two-color, 10);
      &:before{
        border-bottom-color: darken($style-two-color, 10);
      }
    }
  }
  &.style-three{
    background: $style-three-color;
    &:before{
      border-bottom-color: $style-three-color;
    }
    &:focus,
    &:hover{
      background: darken($style-three-color, 10);
      &:before{
        border-bottom-color: darken($style-three-color, 10);
      }
    }
  }
  &.style-four{
    background: $style-four-color;
    &:before{
      border-bottom-color: $style-four-color;
    }
    &:focus,
    &:hover{
      background: darken($style-four-color, 10);
      &:before{
        border-bottom-color: darken($style-four-color, 10);
      }
    }
  }
  &.style-five{
    background: $style-five-color;
    &:before{
      border-bottom-color: $style-five-color;
    }
    &:focus,
    &:hover{
      background: darken($style-five-color, 10);
      &:before{
        border-bottom-color: darken($style-five-color, 10);
      }
    }
  }
  .btn-cross{
    background: $main-crosslink-item-btn-background;
    color: $main-crosslink-item-color;
    &:focus,
    &:hover{
        text-decoration: none;
        background: darken($main-crosslink-item-btn-background, 10);
        color: $main-crosslink-item-color;
    }
  }
  &:focus,
  &:hover{
    color: $main-crosslink-item-color;
    .btn-cross{
      background: darken($main-crosslink-item-btn-background, 10);
    }
  }
}

.cross-helper.style-one{
  .main-crosslink-item{
    color: $main-crosslink-item-color;
    background: $style-one-color;
    &:before{
      border-bottom-color: $style-one-color;
    }
    &:focus,
    &:hover{
      background: darken($style-one-color, 10);
      &:before{
        border-bottom-color: darken($style-one-color, 10);
      }
    }
  }
}

.cross-helper.style-two{
  .main-crosslink-item{
    background: $style-two-color;
    &:before{
      border-bottom-color: $style-two-color;
    }
    &:focus,
    &:hover{
      background: darken($style-two-color, 10);
      &:before{
        border-bottom-color: darken($style-two-color, 10);
      }
    }
  }
}

.cross-helper.style-three{
  .main-crosslink-item{
    background: $style-three-color;
    &:before{
      border-bottom-color: $style-three-color;
    }
    &:focus,
    &:hover{
      background: darken($style-three-color, 10);
      &:before{
        border-bottom-color: darken($style-three-color, 10);
      }
    }
  }
}

.cross-helper.style-four{
  .main-crosslink-item{
    background: $style-four-color;
    &:before{
      border-bottom-color: $style-four-color;
    }
    &:focus,
    &:hover{
      background: darken($style-four-color, 10);
      &:before{
        border-bottom-color: darken($style-four-color, 10);
      }
    }
  }
}

.cross-helper.style-five{
  .main-crosslink-item{
    background: $style-five-color;
    &:before{
      border-bottom-color: $style-five-color;
    }
    &:focus,
    &:hover{
      background: darken($style-five-color, 10);
      &:before{
        border-bottom-color: darken($style-five-color, 10);
      }
    }
  }
}

.main-crosslink-item{
  .btn-cross{
    background: $main-crosslink-item-btn-background;
    color: $main-crosslink-item-color;
    &:focus,
    &:hover{
        text-decoration: none;
        background: darken($main-crosslink-item-btn-background, 10);
        color: $main-crosslink-item-color;
    }
  }
}

.main-crosslink.inner-crosslink{
  .main-corsslink-box{
    border-bottom-color: $main-crosslink-reso-border-color;
  }
}

.main-crosslink-hotline{
  color: $main-crosslink-hotline-color;
  a{
    color: $main-crosslink-hotline-color;
  }
}

.important-item{
  &-label{
    &.style-one{
      background: $style-one-color;
    }
    &.style-two{
      background: $style-two-color;
    }
    &.style-three{
      background: $style-three-color;
    }
    &.style-four{
      background: $style-four-color;
    }
    &.style-five{
      background: $style-five-color;
    }
  }
}

.bgc-main{
  .important-item{
    &-label{
      &.style-one{
        background: $style-one-color;
      }
      &.style-two{
        background: $style-two-color;
      }
      &.style-three{
        background: $style-three-color;
      }
      &.style-four{
        background: $style-four-color;
      }
      &.style-five{
        background: $style-five-color;
      }
    }
  }
}

.main-crosslink{  
  background-color: #f6dfcc;
  .main-crosslink-description{
    background: #F8BB91;
    color: #000;
  }
}