
//* page theme color *//

$style-one-color: #f27927;
$style-two-color: #69c4eb;
$style-three-color: #edbf09;
$style-four-color: #8cb839;
$style-five-color: #c68fbf;
$style-six-color: #f3872b;

//* cross color *//

$header-cross-color: #fff;
$header-cross-active-color: #000;

//* cross *//

$header-cross-background: #4d4d4d;
$header-cross-link-background: #666666;
$header-cross-border-color: #636363;
$header-cross-home-color: #fff;
$header-cross-home-background: #808080;

//* cross style one *//

$header-cross-style-one-border-color: $style-one-color;
$header-cross-style-one-hover: darken($style-one-color, 10);
$header-cross-style-one-color:$header-cross-color;
$header-cross-style-one-active-color: $header-cross-active-color;

//* cross style one menu *//

$header-cross-style-one-header-background: $style-one-color;
$header-cross-style-one-header-color: #000;

//* cross style one card *//

$cross-item-card-style-one-title-color: $base-white;
$cross-item-card-style-one-btn-background: $style-one-color;
$cross-item-card-style-one-btn-color: #000;

//* cross style two *//

$header-cross-style-two-border-color: $style-two-color;
$header-cross-style-two-hover: darken($style-two-color, 10);
$header-cross-style-two-color: $header-cross-color;
$header-cross-style-two-active-color: $header-cross-active-color;

//* cross style two menu *//

$header-cross-style-two-header-background: $style-two-color;
$header-cross-style-two-header-color: #000;

//* cross style two card *//

$cross-item-card-style-two-title-color: $base-white;
$cross-item-card-style-two-btn-background: $style-two-color;
$cross-item-card-style-two-btn-color: $base-white;

//* cross style three *//

$header-cross-style-three-border-color: $style-three-color;
$header-cross-style-three-hover: darken($style-three-color, 10);
$header-cross-style-three-color: $header-cross-color;
$header-cross-style-three-active-color: $header-cross-active-color;

//* cross style three menu *//

$header-cross-style-three-header-background: $style-three-color;
$header-cross-style-three-header-color: #000;

//* cross style two card *//

$cross-item-card-style-three-title-color: $base-white;
$cross-item-card-style-three-btn-background: $style-three-color;
$cross-item-card-style-three-btn-color: $base-white;

//* cross style four *//

$header-cross-style-four-border-color: $style-four-color;
$header-cross-style-four-hover: darken($style-four-color, 10);
$header-cross-style-four-color: #fff;
$header-cross-style-four-active-color: $header-cross-active-color;

//* cross style four menu *//

$header-cross-style-four-header-background: $style-four-color;
$header-cross-style-four-header-color: #000;

//* cross style two card *//

$cross-item-card-style-four-title-color: $base-white;
$cross-item-card-style-four-btn-background: $style-four-color;
$cross-item-card-style-four-btn-color: $base-white;

//* cross style five *//

$header-cross-style-five-border-color: $style-five-color;
$header-cross-style-five-hover: darken($style-five-color, 10);
$header-cross-style-five-color: $header-cross-color;
$header-cross-style-five-active-color: $header-cross-active-color;

//* cross style five menu *//

$header-cross-style-five-header-background: $style-five-color;
$header-cross-style-five-header-color: #000;

//* cross style two card *//

$cross-item-card-style-five-title-color: $base-white;
$cross-item-card-style-five-btn-background: $style-five-color;
$cross-item-card-style-five-btn-color: $base-white;

//* main crosslink item *//

$main-crosslink-item-color: #000;
$main-crosslink-item-btn-color: #000;
$main-crosslink-item-btn-background: #fff;

$main-crosslink-hotline-color: #000;
$main-crosslink-reso-border-color: #000;
