
//* body *//

form,
html,
body{
    color: $text-color;
}

//* buttons *//

.btn-primary{
  background: $btn-primary-background;
  color: $btn-primary-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-primary-background, 10);
      color: $btn-primary-color;
  }
  &.loading{
    &:before{
      border-color: rgba(255, 255, 255, 0.3);
      border-top-color: $second-color;
    }
  }
}

.btn-default{
  background: $btn-default-background;
  color: $btn-default-color;
  &:focus,
  &:hover{
      text-decoration: none;
      background: darken($btn-default-background, 10);
      color: $btn-default-color;
  }
  &.loading{
    &:before{
      border-color: rgba(255, 255, 255, 0.3);
      border-top-color: $main-color;
    }
  }
}

//* buttons end *//

//* title *//

h2,
h3,
h4{
  color: $title-color;
}

.bgc-main{
  h2,
  h3,
  h4{
    color: $title-main-color;
  }
}

//* title end *//

//* ul, ol *//

main{
  ol{
    li{
      &:before{
        color: $ol-number;
      }
    }
  }
  ul{
    li{
      &:before{
        background: $ul-bullet;
      }
    }
    &.custom-list{
      li{
        &:after{
          background:url('../img/list.svg');
        }
      }
    }
  }
}

main{
  .bgc-main{
    ol{
      li{
        &:before{
          color: $ol-main-bullet;
        }
      }
    }
    ul{
      li{
        &:before{
          background: $ul-main-bullet;
        }
      }
    }
  }
}


//* ul, ol end *//

//* table *//

main{
  table{
    tr:not(.person-small-item){
      &:nth-child(even){
        background: $table-even-background;
      }
    }
    td {
      border: 1px solid $table-tr-border;
    }
  }
  .respo-table{
    table{
      thead{
        tr:not(.person-small-item){
          border-bottom: 1px solid $table-tr-border;
        }
      }
      tbody{
        tr:not(.person-small-item){
          border: 1px solid $table-tr-border;
          &:nth-child(even){
            background: $table-even-background;
          }
          @include media($respo-table-break){
            border-bottom: 1px solid $table-tr-border;
          }
          td{
            border-bottom: 1px solid $table-tr-border;
          }
        }
      }
    }
  }
}

//* table end *//

//* links *//

a{
  color: $color-link;
  &:focus,
  &:hover{
    color: darken($color-link, 10);
  }
}

.link{
  color: $color-link;
  &:focus,
  &:hover{
    color: darken($color-link, 10);
  }
}

//* links end *//

//* color box *//

.color-box-one{
  background-color: $main-color;
  color: $base-white;
  a{
    color: $base-white;
    text-decoration: underline;
    &:focus,
    &:hover{
      color: $base-white;
      text-decoration: none;
    }
  }
}

.color-box-two{
  background-color: $base-grey;
  color: $base-black;
  a{
    color: $base-black;
    text-decoration: underline;
    &:focus,
    &:hover{
      color: $base-black;
      text-decoration: none;
    }
  }
}

.color-box-three{
  background-color: $dark-grey;
  color: $base-white;
  a{
    color: $base-white;
    text-decoration: underline;
    &:focus,
    &:hover{
      color: $base-white;
      text-decoration: none;
    }
  }
}

//* color box end *//