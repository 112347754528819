
$px-values: (5px, 10px, 15px, 20px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 65px, 70px, 75px, 80px, 85px, 90px, 95px, 100px);

// bootstrap
$main-left: 20px;
$main-right: 20px;
$main-left-sm: 25px;
$main-right-sm: 25px;
$main-left-lg: 35px;
$main-right-lg: 35px;
$main-left-xl: 40px;
$main-right-xl: 40px;

// container
$container-max-width: 1580px;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-sm: $main-left-sm;
$container-padding-right-sm: $main-right-sm;
$container-padding-left-lg: $main-left-lg;
$container-padding-right-lg: $main-right-lg;
$container-padding-left-xl: $main-left-xl;
$container-padding-right-xl: $main-right-xl;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-lg;
$fluid-container-padding-right-md: $main-right-lg;
$fluid-container-padding-left-xl: 12rem;
$fluid-container-padding-right-xl: 12rem;

// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-sm: (-$main-left-sm);
$row-margin-right-sm: (-$main-right-sm);
$row-margin-left-lg: (-$main-left-lg);
$row-margin-right-lg: (-$main-right-lg);
$row-margin-left-xl: (-$main-left-xl);
$row-margin-right-xl: (-$main-right-xl);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-sm: $main-left-sm;
$col-padding-right-sm: $main-right-sm;
$col-padding-left-lg: $main-left-lg;
$col-padding-right-lg: $main-right-lg;
$col-padding-left-xl: $main-left-xl;
$col-padding-right-xl: $main-right-xl;

// typography
$font-family: 'proxima-nova', sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 22px;
$min-font-size-perex: 18px;
$line-height-perex: 26px;
$min-line-height-perex: 22px;

// small text
$font-size-small: 16px;
$min-font-size-small: 16px;
$line-height-small: 20px;
$min-line-height-small: 20px;

// table
$respo-table-break: "md";

// menu
$hamburger-width: 6rem;
$menu-break: "lg";

// cross
$scross-break: "md";

// inputs
$input-height: 70px;
$textarea-height: 180px;

// selectric
$select-arrow-code: "\e9a0";
$select-use-triangle: 0;
$select-height: 50px;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

//scale ratios
$scale-base-size: 10px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;


//*** lazy load ***
$imgLoadAnimation: animateLoaded__ 0.1s ease-out forwards;
