
@import "../_mixins/pux/_breakpoints.scss";
@import "_variables.scss";

//* header *//

header{
  background: $header-background;
  .header{
    &-menu{
      background: $header-menu-respo-background;
      @include media($menu-break){
        background: none;
      }
      ul{
        li{
          & > span,
          & > a{
            color: $header-link-color;
            &:focus,
            &:hover{
              color: $header-link-hover-color;
            }
          }
          &.Highlighted{
            & > span,
            & > a{
              color: $header-link-hover-color;
            }
          }
        }
        ul{
          background: darken($header-submenu-background,7);
          @include media($menu-break){
            background: $header-submenu-background;
          }
          &::-webkit-scrollbar-track {
            background: $scrollbar-background;
          }
          &::-webkit-scrollbar-thumb {
            background: $scrollbar-progress;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: darken($scrollbar-progress,10);
          }
          li{
            border-color: $header-submenu-li-border-color;
            & > span,
            & > a{
              &:focus,
              &:hover{
                background: $header-submenu-link-hover-background;
                color:$header-submenu-link-hover-color;
              }
            }
            &:hover{
              @include media($menu-break){
                & > span,
                & > a{
                  background: $header-submenu-link-hover-background;
                  color:$header-submenu-link-hover-color;
                }
                span.menu-openner{
                  background: $header-submenu-link-hover-background;
                  &:before{
                    color: $base-white;
                    @include media($menu-break){
                      color: $header-submenu-link-hover-color;
                    }
                  }
                }
              }
            }
            ul{
              border-color: $header-submenu-li-border-color;
              background: darken($header-submenu-background,15);
              @include media($menu-break){
                background: $header-submenu-background;
              }
              li{
                border-color: $header-submenu-li-border-color;
                @include media($menu-break){
                    border-color: darken($header-submenu-link-hover-background,10);
                }
                span,
                a{
                  background: #fff; // doplnit vlastní barvu
                  color: $text-color; // doplnit vlastní barvu
                  &:focus,
                  &:hover{
                    background: darken($header-submenu-link-hover-background,5);
                    color:$header-submenu-link-hover-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .header-hamburger .open-menu span{
    background: $hamburger-color;
    &:before,
    &:after{
      background: $hamburger-color;
    }
  }
}

.header-menu{
  a{
    &:before{
      color: $menu-icon-color;
    }
  }
  .menu-360{
    a{
      color: $menu-360-color;
      &:before{
        color: $menu-360-color;
      }
      &:focus,
      &:hover{
        color: darken($menu-360-color, 10);
        &:before{
          color: darken($menu-360-color, 10);
        }
      }
    }
  }
}

.header-hamburger{
  .open-search{
    color: $hamburger-search-color;
  }
}

.header-top{
  background: $header-contact-background;
  @include media(md){
    background: none;
  }
}

.menu-openner{
  background: $header-menu-openner;
  &:before{
    color: $header-menu-openner-color;
  }
  @include media($menu-break){
    background: none;
    &:before{
      color: $menu-icon-color;
    }
  }
}

.header-email,
.header-phone{
  color: $header-contact-respo-color;
  @include media(md){
    color: $header-contact-color;
  }
  &:focus,
  &:hover{
    color: darken($header-contact-respo-color, 10);
    @include media(md){
      color: darken($header-contact-color, 10);
    }
  }

  body.contrast & {
    color: $main-contrast !important;

    &:focus,
    &:hover{
      color: darken($main-contrast, 10) !important;
    }
  }
}

//* size button *//

.option-box{
  .size-plus,
  .size-minus{
    color: $size-button-color;
    background: $size-button-backgound;
    span{
      background: $base-white;
      color:$text-color;
    }
  }
  .contrast-item{
    color: $contrast-button-color;
    background: $contrast-button-background;
    span{
      background: $base-white;
      color:$text-color;
    }
    &.active{
      background: $contrast-button-active-background;
    }
  }
}

//* search *//

.search-overlay{
  background: rgba($search-overlay-background, 0.95);
  color: $search-overlay-color;
  a{
    color: $search-overlay-link-color;
  }
  .search-panel-btn{
    color: $search-button-color;
    background: rgba($search-button-background,0.8);
    &:focus,
    &:hover{
      background: rgba($search-button-background,0.65);
    }
  }
  input[type="text"].search-panel-input{
    @include placeholder{
      color: $search-color-placeholder;
    }
  }
  .search-close{
    color: $search-overlay-color !important;
    &:focus,
    &:hover{
      background: rgba($search-close-hover-background, 0.5);
    }
  }
}

.search-item{
  &-top {
      @include media(xxs, sm) {
        color: $search-item-breadcrumbs-respo-color;
      }
  }
  &-content{
    color: $search-item-color;
    &-name{
      color: $search-item-name-color;
    }
    &:focus,
    &:hover{
      color: $search-item-color;
      .search-item-content-name{
        color: darken($search-item-name-color, 10);
      }
    }
  }
}

//* footer *//

footer {
  background: $footer-background;
  :before {
    color: $footer-icon-color;
  }
  a {
    color: $footer-link-color;

    &:focus,
    &:hover {
      color: $footer-link-hover-color;
    }

    &:before {
      color: $footer-icon-color;
    }
  }
  .footer-bottom {
    a {
      color: $base-white;
      font-weight: 700;
      text-decoration: underline;
      transition: all ease 0.3s;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  .insert-mail,
  .insert-phone{
    color:$footer-contact-link-color;
    &:focus,
    &:hover{
      color: $footer-contact-link-hover-color;
    }
  }
  .copyright{
    background: $copyright-background;
    color: $copyright-color;
  }
  .footer-links a,
  .footer-logo-link {
    color: $footer-links-color;
    &:focus,
    &:hover{
      color: darken($footer-links-color, 10);
    }
  }
}

//* footer end *//

//* page header *//
.breadcrumbs{
  color: $page-header-breadcrumbs-color;
  a{
    color: $page-header-breadcrumbs-color;
    &:focus,
    &:hover{
      color: $page-header-breadcrumbs-color;
    }
  }
}

.header-intro{
   //background: $main-color;
  .header-intro-title,
  .header-intro-subtitle{
    color: $page-header-color;
  }
  &:after{
    background: $main-color;
  }
}

body{
  &.contrast{
    .header-intro{
      &:after{
        background: $main-contrast;
      }
    }
  }
}

//* page header end *//

//* containers *//

.bgc-main{
  background: $container-main-background;
  color: $container-main-color;
  .circle-btn{
    background: linear-gradient(to bottom, $container-main-background 0%, $container-main-background 50%,  transparent 100%);
    span:before{
      color: $separator-main-color;
    }
  }
}

.bgc-gray{
  background: $container-grey-background;
  .circle-btn{
    background: linear-gradient(to bottom, $container-grey-background 0%, $container-grey-background 50%,  transparent 100%);
    span:before{
      color: $separator-grey-color;
    }
  }
}

.bgc-dark{
  background: $container-dark-background;
  .circle-btn{
    background: linear-gradient(to bottom, $container-dark-background 0%, $container-dark-background 50%,  transparent 100%);
    span:before{
      color: $separator-dark-color;
    }
  }
  &.widget-pattern{
    .circle-btn{
      background: linear-gradient(to bottom, $container-dark-with-ico-background 0%, $container-dark-with-ico-background 50%,  transparent 100%);
    }
  }
}

.bgc-white{
  background: $container-white-background;
  .circle-btn{
    background: $container-white-background;
    background: linear-gradient(to bottom, $container-white-background 0%, $container-white-background 50%,  transparent 100%);
    span:before{
      color: $separator-white-color;
    }
  }
}

body{
  &.contrast{
    .bgc-main{
      background: $main-contrast;
      .circle-btn{
        background: linear-gradient(to bottom, $main-contrast 0%, $main-contrast 50%,  transparent 100%);
      }
    }
  }
}


//* containers end *//

//* filter *//

.filter-container{
  background: $filter-background;
  label{
    color: $filter-color;
  }
  .link{
    color: $filter-link-color;
    &:focus,
    &:hover{
      color: darken($filter-link-color, 10);
    }
  }
}

.bgc-gray{
  .filter-container{
    background: $filter-grey-background;
  }
}

.bgc-main{
  .filter-container{
    background: $filter-main-background;
    label{
      color: $filter-main-color;
    }
    .link{
      color: $filter-main-link-color;
      &:focus,
      &:hover{
        color: darken($filter-main-link-color, 10);
      }
    }
  }
}

//* filter end *//

//* form *//

.radio{
  input[type="radio"]{
    & +label{
      &:before{
        border-color: $radio-border;
        background: $radio-background;
      }
    }
    &:checked{
      & +label{
        &:before{
          background: $radio-active-background;
        }
      }
    }
  }
}

.checkbox{
  input[type="checkbox"]{
    & +label{
      &:before{
        background: $checkbox-background;
        color:$checkbox-color;
      }
    }
    &:checked{
      & +label{
        &:before{
          color:$checkbox-active-color;
        }
      }
    }
  }
}

label{
  color: $text-color;
  &.required{
    &:after{
      color:$main-color;
    }
  }
}

input[type="submit"].btn-primary{
    background: $btn-default-background;
    color: $btn-default-color;
    &:focus,
    &:hover{
      background: darken($btn-default-background, 10);
    }
}

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"]{
  border-color: $border-color;
  background: $input-background;
  color: $text-color;
  @include placeholder{
    color: $color-placeholder;
  }
  &:focus{
    border-color: $focus-border-color;
  }
}

textarea.form-control,
textarea{
  &:focus{
    border-color: $focus-border-color;
  }
}

.file{
  label{
    color: $upload-color;
    background: $upload-background;
  }
  .file-name{
    background: $file-name-background;
    color: $file-name-color;
  }
}

.file-new{
  .file-placeholder{
    background: $base-white;
    border-color: $base-white;
  }
}

.selectric {
  background: $select-background;
  .label {
    color: $select-color;
  }
  .button {
    &:after {
      color: $select-arrow-color;
    }
  }
}

.selectric-items {
  background-color: $select-li-background;

  li {
    color: $select-color;
    background: $select-li-background;

    &.selected,
    &.selected.highlighted {
      color: $select-li-selected-color;
      background: $select-li-selected-background;
    }

    &:hover,
    &.highlighted {
      background: $select-li-hover-background;
    }
    &:nth-child(odd){
      background: $select-li-odd-background;
      &:first-child{
        background: $select-li-first-background;
        color: $select-li-first-color;
      }
      &:hover,
      &.highlighted {
        background: $select-li-hover-background;
      }
      &.selected {
        color: $select-li-selected-color;
        background: $select-li-selected-background;
      }
    }
    &:first-child{
      background: $select-li-first-background !important;
      color: $select-li-first-color !important;
    }
  }

  .disabled {
    color: $base-black !important;
  }

  .selectric-group {
    .selectric-group-label {
      color: $base-black;
    }
  }
}

.bgc-main,
.bgc-gray{
  .selectric{
    background: $selectric-grey-background;
  }
  .form-control,
  select,
  select.form-control,
  textarea,
  textarea.form-control,
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"]{
    background: $input-grey-background;
  }
}

.bgc-main{
  .checkbox{
    input[type="checkbox"]{
      & +label{
        &:before{
          background: $checkbox-main-background;
          color:$checkbox-main-color;
        }
      }
      &:checked{
        & +label{
          &:before{
            color:$checkbox-active-color;
          }
        }
      }
    }
  }
}


//* form end *//

//* contact *//

.contact-item{
  background: $contact-backgrond;
  .contact-ic{
    &:before{
      color: $contact-icon-color;
    }
  }
  .ic-phone,
  .ic-email{
    color: $contact-link-color;
    &:focus,
    &:hover{
      color: darken($contact-link-color, 10);
    }
  }
}

//* contact end *//

//* line helper *//

.events-line,
.line-helper{
  &:before{
    border-left-color: $line-helper-border-color;
  }
}

.bgc-main{
  .events-line,
  .line-helper{
    &:before{
      border-left-color: $line-helper-main-border-color;
    }
  }
}

//* line helper end *//

//* back to top *//

.back-to-top {
  background-color: #565656;
  &:hover, &:focus {
    border-color: #565656;
    &::after {
      color: #fff;
    }
  }
  &::after {
    color: #fff;
  }
}

//* back to top end *//

//* chatbot banner *//

.form-chat-banner{
  background-color: $main-color;
  color: $base-white;
  &:focus,
  &:hover{
    background-color: darken($main-color, 10);
  }
}

//* chatbot banner end *//

.dtp {
  & > .dtp-content{
    background: $datepicker-full-background;
    & > .dtp-date-view{
       .dtp-header {
          background: $datepicker-header-background;
          color: $datepicker-header-color;
      }
    }
  }
  div.dtp-date,
  div.dtp-time {
     background: $datepicker-option-backgorund;
     color: $datepicker-option-color;
  }
  div.dtp-actual-year {
    color: #DCEDC8;
  }
  .dtp-close {
    & > a {
      color: #fff;
    }
  }
  &-btn-ok{
    background: darken($datepicker-full-background, 10);
  }
}

.dtp {
  table{
    &.dtp-picker-days {
      tr{
        & > td{
          & > span.dtp-select-day {
            color: #BDBDBD!important;
          }
          & > a{
            color: #212121;
            &.selected{
              background: $datepicker-selected-background;
              color: $datepicker-selected-color;
            }
          }
        }
        & > th {
           color: $datepicker-th-color;
           border-top:1px solid $datepicker-th-border;
           border-bottom:2px solid $datepicker-th-border;
        }
      }
    }
  }
  .dtp-picker-time{
    & > a{
      color: #212121;
    }
  }
}

.dtp{
  .p10{
    & > a{
      color: $datepicker-header-arrows-color;
    }
  }
  a.dtp-meridien-am,
  a.dtp-meridien-pm {
     color: #212121;
     background: #eee;
  }
  .dtp-actual-meridien a.selected {
     background: #689F38;
     color: #fff;
  }
}

.year-picker-item{
    &:hover{
      color:$datepicker-year-picker-color;
    }
    &.active{
      color:$datepicker-year-picker-color;
    }
}

.widget-container.bgc-image{
  &:after{
    background-color: $main-color;
    opacity: 0.85;
  }
}